import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "./src/state/store";
import { useAccount } from "./src/state/useAccount";
import Loading from "./src/components/loading";
import config from "./config";

const LoadAccountProvider = ({ children }) => {
    const account = process.env.ACCOUNT;
    let accountObject = config.accounts[account];

    if (process.env.NODE_ENV === `development`) {
        console.log(`\n\n\naccount = ${JSON.stringify(account,null,2)}`);
        console.log(`\n\n\naccountObject = ${JSON.stringify(accountObject,null,2)}`);
    }

    // Default to an empty string if accountObject or sheetID is undefined
    const sheetID = accountObject?.sheetID || "";

    // Call the hook unconditionally
    const { loading, error } = useAccount(sheetID);

    if (!accountObject) {
        console.error(`No account found for ACCOUNT=${account}. Please check your environment variables or config.`);
        return <div>Error: Account not found.</div>;
    }

    if (!sheetID) {
        console.error(`No sheetID found for account: ${account}. Please check your configuration.`);
        return <div>Error: sheetID not found for the account.</div>;
    }

    if (loading) return <Loading />;
    if (error) return <div>Error loading account data: {error}</div>;

    return <>{children}</>;
};

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <LoadAccountProvider>
                {element}
            </LoadAccountProvider>
        </PersistGate>
    </Provider>
);
